<template>
  <div class="element">
    <h2 class="headline">SURVEY ERROR</h2>
    <p>{{ surveyErrorMessage }}</p>
    <p><button class="lsc-button lsc-rounded red" @click="close">{{ x('close')}}</button></p>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import ComponentEventNames from '@/enums/component-event-names';
  import translate from '@/components/Mixins/Translate';

  export default {
    name: 'survey-error',
    emits: [
      ComponentEventNames.didLoad,
      ComponentEventNames.close,
    ],
    mixins: [translate],
    computed: {
      ...mapState('SurveyStore', ['surveyErrorMessage']),
    },
    mounted() {
      this.$emit(ComponentEventNames.didLoad);
    },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style scoped>
  .headline {
    text-transform: uppercase;
    margin: 0;
    color: #86adc7;
    font-weight: 700;
  }

  p {
    margin-top: 10px;
  }

  button {
    width: 100px;
    text-align: center;
  }
</style>
